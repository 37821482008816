export const mainSchool = {
  id: 1,
  // TODO: i18n
  name: 'Наши Пенаты',
}

export const olympSchool = {
  id: 4,
  name: 'Олимп-плюс',
}

export const educontSchoolId = 31
